import React from "react";
import Layout from "@/components/layout";
import CallToActionOne from "@/components/call-to-action-one";
import Footer from "@/components/footer";
import BlogHome from "@/components/blog-home";
import ServiceHomeTwo from "@/components/service-home-two";
import WcShopifyExperts from "@/components/wc-shopify-experts";
import ClientCarouselOne from "@/components/client-carousel-one";
import TeamCarousel from "@/components/team-carousel";
import FunfactOne from "@/components/funfact-one";
import TrustedClient from "@/components/trusted-client";
import PortfolioHome from "@/components/portfolio-home";
import HeaderOne from "@/components/header-one";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import SliderOne from "@/components/slider-one";

const HomeOne = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Expertos en desarrollo a medida - Agencia Fullstack - Witocorp">
          <HeaderOne />
          <SliderOne />
          <ServiceHomeTwo />
          <WcShopifyExperts />
          {/* <PortfolioHome /> */}
          <FunfactOne />
          {/* <TrustedClient /> */}
          {/* <TeamCarousel /> */}
          {/* <ClientCarouselOne /> */}
          {/* <BlogHome /> */}
          <CallToActionOne extraClassName="ready_2" buttonClass="red_bg" />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default HomeOne;
