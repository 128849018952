import React from "react";
import { AboutTwoData, WcShopifyExpertsData } from "@/data";
import { Col, Container, Row } from "react-bootstrap";

const WcShopifyExperts = () => {
  const { sectionContent, button, gallery } = AboutTwoData;
  const { sectionContentWc, posts, image } = WcShopifyExpertsData;

  const zeroPad = (num, numZeros) => {
    var n = Math.abs(num);
    var zeros = Math.max(0, numZeros - Math.floor(n).toString().length);
    var zeroString = Math.pow(10, zeros).toString().substr(1);
    if (num < 0) {
      zeroString = "-" + zeroString;
    }

    return zeroString + n;
  };

  return (
    <section className="ab_agency wtCommonSection">
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} className="PR_79">
            <h4 className="sub_title">{sectionContent.subTitle}</h4>
            <h2 className="sec_title MB_45">{sectionContent.title}</h2>
            <p className="sec_desc">{sectionContent.text}</p>
            {/* <a className="common_btn red_bg wc-hidden-mobile" href={button.url}>
              <span>{button.label}</span>
            </a> */}
          </Col>
          <Col lg={5} md={6} sm={12} className="wc-shopify-experts-div">
            <div className="features_content">
              {posts.map(({ title, text }, index) => {
                return (
                  <div className="singleFeature" key={index}>
                    <div className="f_count">{zeroPad(index + 1, 2)}</div>
                    <h3>{title}</h3>
                    <p>{text}</p>
                  </div>
                );
              })}
              {/* <a className="common_btn red_bg wc-hidden-desktop" href={button.url}>
                <span>{button.label}</span>
              </a> */}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default WcShopifyExperts;
